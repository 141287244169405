import React from "react"
import git from "../imagens/GitHub Icon.png"
import instagram from "../imagens/Instagram Icon.png"

export default function Footer() {
  return (
    <div className="footer">
      <a href="https://github.com/andersonbruno02">
        <img src = {git} className="icone" alt="ícone github"></img>
      </a>
      <a href="https://www.instagram.com/bocaoubruno/">
        <img src = {instagram} className="icone" alt="ícone instagram"></img>
      </a>
    </div>
    
  )
}