import React from "react"
import jake from "../imagens/jake.jpg"

export default function Info() {
  return (
    <div className="info">
      <img src = {jake} className="foto" alt="Jake um cachorro legal ouvindo musicas com seus fones de ouvido."></img>
      <h1 className="nome">Anderson Bruno Lima</h1>
      <h3 className="stack">Desenvolvedor BackEnd</h3>
      <div className="botoes">
        <a href="https://www.linkedin.com/in/anderson-bruno-lima/" className="botao">
          LinkedIn
        </a>
      </div>
    </div>
    
  )
}