import './App.css';
import Info from './components/Info';
import Interesses from './components/Interesses';
import Sobre from './components/Sobre';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Info />
      <Sobre />
      <Interesses />
      <Footer />
    </div>
  );
}

export default App;
