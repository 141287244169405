import React from "react"

export default function Interesses() {
  return (
    <div className="interesse">
      <h2>Interesses</h2>
      <p>docker rules??</p>
    </div>
    
  )
}
