import React from "react"

export default function Sobre() {
  return (
    <div className="sobre">
      <h2>Sobre</h2>
      <p>Apenas um dev curioso querendo aprender front-end</p>
    </div>
    
  )
}